/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, matchPath, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Topbar} from './Topbar'
import {clientIsTizen} from '../../../../app/common/tizen/helpers'
import {PRINTER_STATUSES, setAlertDisplayed} from '../../../../app/services/TizenSystemSlice'
import {RootState} from '../../../../app/store'
import {NoWifiSVG} from '../../../assets/ts/components/NoWifiSVG'
import {TizenDebug} from '../../../../app/modules/hotel/FaceAuth/TizenIntegration'
import {useErrorAlert, useTimedErrorAlert, useWarningAlert} from '../../../../app/modules/utilities/alerts'
import {useLocationBasedBtnColor} from '../../../../app/common/hooks/buttons'
import {PrinterSVG} from '../../../../app/common/svgs/PrinterSVG'
import {constants, KIOSK_GLOBAL_ON} from '../../../../app/config/constants'
import {useIntl} from 'react-intl'
import {LANGUAGE_FLAGS, LANGUAGE_NAMES, useInternationalization} from '../../../../app/modules/i18n/i18nProvider'
import {LanguageSettingsModal} from '../../../../app/modules/layout/LanguageSettingsModal'
import {useLocation} from 'react-router'
import {useKioskProducts} from '../../../../app/common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS, orderingRoutes} from '../../../../app/routing/RoutePaths'
import {getAuth, GetApiUrl} from '../../../../app/modules/auth/core/AuthHelpers'
import {useCacheImg} from '../../../../app/common/hooks/useCacheImg'

interface HeaderTypes {
   styles: any //passdown an object with styles e.g. {width:'100px', height:'100px'}
   to: string //href to link
   imgURL: string //image URL
   useSVG: boolean //only certain style work with this imported SVG component
   pathname: string //url pathname helps header text
   viewDateTime: boolean //truthy value to view Topbar
   imgClassName: string //used to help adjust image size
   LogoFullPath: boolean //truthy value to view Topbar
   onBackButton?: () => void
}
export function HeaderWrapper({
   to,
   imgURL,
   imgClassName,
   pathname,
   useSVG,
   viewDateTime,
   styles,
   LogoFullPath = false,
   onBackButton,
}: HeaderTypes) {
   const intl = useIntl()
   const settings = useSelector((state: RootState) => state.kiosk_settings)
   const {selectedLang, setLanguage} = useInternationalization()
   const fireError = useErrorAlert()
   const fireTimedError = useTimedErrorAlert()
   const fireWarningAlert = useWarningAlert()
   const locationMetaData = useSelector((state: RootState) => state.location_data.derived_values)
   const [headerText, setHeaderText] = useState('')
   const [displayBackButton, setDisplayBackButton] = useState<boolean>(true)
   const {classes, attributes} = useLayout()
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   const header_page_title = useSelector((state: any) => state.header_page_title.value)
   const AppDispatch = useDispatch()
   let navigateTo = useNavigate()
   const headerRef = useRef<null | HTMLDivElement>(null)
   const BtnClrClass = useLocationBasedBtnColor()
   const location = useLocation()
   const [showModal, setShowModal] = useState(false)
   const {orderingEnabled, checkInEnabled} = useKioskProducts()

   const renderLanguageButton = () => {
      const excludedRoutes = [
         '/index.html',
         '/',
         MODULE_ROUTE_PATHS.app_auth.index,
         MODULE_ROUTE_PATHS.terminal_configuration.index,
         MODULE_ROUTE_PATHS.ordering.ORDER_HOME,
         MODULE_ROUTE_PATHS.ordering.MENU,
         MODULE_ROUTE_PATHS.ordering.ITEMS,
         MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT,
         MODULE_ROUTE_PATHS.offline.index,
      ]
      for (const pattern of excludedRoutes) {
         if (matchPath(pattern, location.pathname) != null) {
            return false
         }
      }
      return true
   }

   // SELF ORDERING WILL NOT SUPPORT INTERNATIONALIZATION ON FIRST RELEASE
   useEffect(() => {
      for (const pattern of orderingRoutes) {
         if (matchPath(pattern, location.pathname) != null && selectedLang === 'es') {
            setLanguage('en')
            break
         }
      }
   }, [location.pathname, selectedLang])

   const dangerColor = constants.theme.danger
   const warningColor = constants.theme.warning
   const tizenPaperStatus = tizenSystem.printer.paper_status

   //switch case for header text
   const addHeaderText = (pathname: any) => {
      TizenDebug(`[PathName] ${pathname}`)

      switch (pathname) {
         case MODULE_ROUTE_PATHS.terminal_configuration.index:
            return setHeaderText('New Terminal')
         case MODULE_ROUTE_PATHS.checkIn.check_in_home:
         case MODULE_ROUTE_PATHS.ordering.ORDER_HOME:
         case MODULE_ROUTE_PATHS.ordering.MENU:
            return setDisplayBackButton(orderingEnabled && checkInEnabled)
         case '/':
         case '/index.html':
         case MODULE_ROUTE_PATHS.app_auth.index:
         case MODULE_ROUTE_PATHS.dashboard.index:
         case MODULE_ROUTE_PATHS.offline.index:
            return setDisplayBackButton(false)
         default:
            return setDisplayBackButton(true)
      }
   }

   //NETWORK CONNECTION INDICATOR HOOK
   useEffect(() => {
      if (!tizenSystem.network.available && clientIsTizen()) {
         headerRef.current!.style.backgroundColor = 'red'
         const msg = `${intl.formatMessage({id: 'header.alert.let_staff_know_terminal_is_offline'})}<br/><br/>
         ${intl.formatMessage({id: 'header.alert.check_network_or_wifi'})}<br/>
         ${intl.formatMessage({id: 'header.alert.check_other_device_are_online'})}`

         const loginRoutes = ['/', '/index.html', MODULE_ROUTE_PATHS.app_auth.index]
         const pathNotLogin = !loginRoutes.includes(pathname)
         const kioskConfigRoute = MODULE_ROUTE_PATHS.terminal_configuration.index
         if (pathNotLogin) {
            if (pathname == kioskConfigRoute) navigateTo('/')
            else {
               if (orderingEnabled || checkInEnabled) {
                  navigateTo(MODULE_ROUTE_PATHS.offline.index)
               } else {
                  navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
               }
            }
         }

         if (!tizenSystem.network.alertDisplayed) {
            fireTimedError(msg, 60).then((res) => {
               AppDispatch(setAlertDisplayed(true))
            })
         }
      } else {
         headerRef.current!.style.backgroundColor = ''
      }
   }, [tizenSystem.network.available])

   useEffect(() => {
      addHeaderText(pathname)
      return () => {
         addHeaderText(pathname)
      }
   }, [to, header_page_title])

   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softpoint_small_black.svg'
         : '../media/logos/softpoint_small.svg'

   let {cachedImage} = useCacheImg({imageUrl: settings?.value?.header_banner ?? null, placeholderUrl: ''})
   if (!tizenSystem.network.available && clientIsTizen()) {
      cachedImage = ''
   }

   const handleBackButton = () => {
      if (pathname.indexOf(MODULE_ROUTE_PATHS.ordering.MENU) > -1) {
         if (!settings?.value?.dine_in || !settings?.value?.order_type_id) {
            return navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
         } else {
            return navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
         }
      }
      if (pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT) > -1)
         return navigateTo(MODULE_ROUTE_PATHS.ordering.MENU)
      if (pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ORDER_HOME) > -1)
         return navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
      if (pathname.endsWith(MODULE_ROUTE_PATHS.checkIn.check_in_home))
         return navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
      if (pathname.indexOf(MODULE_ROUTE_PATHS.terminal_configuration.index) > -1 && onBackButton) {
         onBackButton()
         return
      }

      return navigateTo(-1)
   }

   const handlePrintIcon = () => {
      let message = intl.formatMessage({id: 'header.alert.printer_low_on_paper'})
      if (tizenSystem.printer.paper_status == PRINTER_STATUSES.OUT) {
         message = intl.formatMessage({id: 'header.alert.printer_outof_paper'})
         fireError(message)
         return
      }

      fireWarningAlert(message)
   }

   let bannerImage = ''
   if (pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ORDER_HOME) > -1 && settings?.value?.header_banner) {
      bannerImage = cachedImage
   }

   return (
      <>
         {pathname.indexOf(MODULE_ROUTE_PATHS.ordering.MENU) > -1 ||
         pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ORDER_HOME) > -1 ||
         pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ITEMS) > -1 ? (
            <div
               id='kt_header'
               ref={headerRef}
               style={styles}
               className={clsx(
                  'header',
                  classes.header.join(' '),
                  `align-items-stretch ${
                     pathname.indexOf(MODULE_ROUTE_PATHS.ordering.ORDER_HOME) > -1 ? 'big-header' : ''
                  } `
               )}
               {...attributes.headerMenu}
            >
               {bannerImage != '' ? (
                  <div
                     className='card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover hadder-banner-bg'
                     style={{
                        width: '100%',
                        backgroundColor: 'var(--kt-primary)',
                        backgroundImage: `url(${bannerImage})`,
                     }}
                  ></div>
               ) : (
                  <div
                     className='d-flex align-items-center justify-content-center flex-grow-1 flex-lg-grow-0'
                     style={{width: '100%', backgroundColor: 'var(--kt-primary)'}}
                  >
                     <Link className='p-3' to={to}>
                        {useSVG ? (
                           <KTSVG path={imgURL} className={imgClassName} />
                        ) : (
                           <img
                              alt='Logo'
                              src={LogoFullPath ? imgURL : toAbsoluteUrl(imgURL)}
                              className={`rounded-1 h-90px`}
                              onError={({currentTarget}) => {
                                 currentTarget.onerror = null // prevents looping
                                 currentTarget.src = toAbsoluteUrl(logoPath)
                              }}
                           />
                        )}
                     </Link>
                  </div>
               )}
            </div>
         ) : (
            <>
               <div
                  id='kt_header'
                  ref={headerRef}
                  style={styles}
                  className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
                  {...attributes.headerMenu}
               >
                  <div
                     className={clsx(
                        classes.headerContainer.join(' '),
                        'd-flex align-items-stretch justify-content-between'
                     )}
                  >
                     {/* style={{backgroundColor:"#FFF",padding:"5px"}} */}
                     <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                        {displayBackButton ? (
                           <>
                              <button className='btn btn-primary text-white me-5 p-3 ' onClick={handleBackButton}>
                                 <i className={`bi bi-arrow-left fw-bold ${BtnClrClass}`} style={{fontSize: '30px'}} />
                              </button>
                           </>
                        ) : null}

                        <Link className='py-3' to={to}>
                           {useSVG ? (
                              <KTSVG path={imgURL} className={imgClassName} />
                           ) : (
                              <img
                                 alt='Logo'
                                 src={LogoFullPath ? imgURL : toAbsoluteUrl(imgURL)}
                                 className={imgClassName}
                                 onError={({currentTarget}) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = toAbsoluteUrl(logoPath)
                                 }}
                              />
                           )}
                        </Link>
                        <h1 className='fw-bold dislpay-6 m-0 ps-2'>
                           {header_page_title === '' ? headerText : header_page_title}
                        </h1>
                     </div>
                     {/* begin::Wrapper */}
                     <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'>
                        {renderLanguageButton() && (
                           <div className='d-flex align-items-center pe-2'>
                              <button
                                 className={`btn btn-bg-secondary d-flex`}
                                 onClick={() => setShowModal((prevState) => !prevState)}
                              >
                                 <div>
                                    <img src={LANGUAGE_FLAGS[selectedLang]} height='20px' className='pe-3' alt='' />
                                 </div>
                                 <div>{LANGUAGE_NAMES[selectedLang]}</div>
                              </button>
                           </div>
                        )}
                        <div className='d-flex align-items-center pe-2'>
                           {clientIsTizen() && !tizenSystem.network.available ? <NoWifiSVG /> : null}
                           {tizenPaperStatus != PRINTER_STATUSES.OK ? (
                              <PrinterSVG
                                 fill={tizenPaperStatus == PRINTER_STATUSES.OUT ? dangerColor : warningColor}
                                 onClick={handlePrintIcon}
                              />
                           ) : null}
                        </div>
                        <div className='d-flex align-items-stretch flex-shrink-0'>
                           {viewDateTime ? <Topbar /> : <></>}
                        </div>
                     </div>
                     {/* end::Wrapper */}
                  </div>
               </div>
               <LanguageSettingsModal showModal={showModal} setShowModal={setShowModal} />
            </>
         )}
      </>
   )
}
