export const RouteSegments = {
   selfOrdering: {
      ORDER_HOME: 'order-type',
      MENU: 'menu',
      ORDER_CHECKOUT: 'order-checkout',
      ORDER_COMPLETE: 'order-complete',
      ITEMS: 'items',
   },
   checkIn: {
      check_in_home: 'checkin',
      employee_login: 'emp_login',
      reservation_confirmation: 'reservation-confirmation',
      biometric_auth: 'face_auth',
      qr_auth: 'qr_auth',
   },
} as const

export const MODULE_ROUTE_PATHS = {
   ordering: {
      ORDER_HOME: '/app/self_ordering/order-type',
      MENU: '/app/self_ordering/menu',
      ORDER_CHECKOUT: '/app/self_ordering/order-checkout',
      ORDER_COMPLETE: '/app/self_ordering/order-complete',
      ITEMS: '/app/self_ordering/items',
   },
   checkIn: {
      check_in_home: '/app/checkin',
      employee_login: '/app/checkin/emp_login',
      reservation_confirmation: '/app/checkin/reservation-confirmation',
      biometric_auth: '/app/checkin/face_auth',
      qr_auth: '/app/checkin/qr_auth',
   },
   dashboard: {
      index: '/app/dashboard',
   },
   settings: {
      index: '/app/settings',
   },
   offline:{
      index: '/app/offline',
   },
   app_auth: {
      index: '/login',
   },
   terminal_configuration: {
      index: '/kiosk_terminal',
   },
   idle:{
      index: '/idle',
   }
} as const

export const orderingRoutes = [
   MODULE_ROUTE_PATHS.ordering.ORDER_HOME,
   MODULE_ROUTE_PATHS.ordering.MENU,
   MODULE_ROUTE_PATHS.ordering.ITEMS,
   MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT,
]

export const getOrderingPaths = () => MODULE_ROUTE_PATHS.ordering
export const getCheckInPaths = () => MODULE_ROUTE_PATHS.checkIn

export const getOrderingSegments = () => RouteSegments.selfOrdering
export const getCheckInSegments = () => RouteSegments.checkIn

// export type AppModuleRoute = typeof MODULE_ROUTE_PATHS[keyof typeof MODULE_ROUTE_PATHS]
